import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { PageLayout } from "../components/page-layout";
import { doDisconnect, getStravaStatus, doConnect } from "../services/strava.service";
import { Loader2 } from "lucide-react";

// Import the image
import stravaConnectButton from "../assets/btn_strava_connectwith_orange@2x.png";

export const ConnectPage: React.FC = () => {
  const [message, setMessage] = useState<string>("");
  const [isDisconnectPending, setIsDisconnectPending] = useState<boolean>(false);
  const [stravaStatus, setStravaStatus] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { getAccessTokenSilently } = useAuth0();
  

  useEffect(() => {
    const handleInitialLoad = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');

        if (code) {
          // Handle OAuth callback
          const { data, error } = await doConnect(accessToken, code);
          if (error) {
            setMessage(`Error: ${error.message}`);
          } else {
            console.log('Successfully connected to Strava');
            setStravaStatus("connected");
            // Remove the 'code' parameter from the URL
            window.history.replaceState({}, document.title, window.location.pathname);
          }
        } else {
          // Fetch Strava status if no code is present
          const { data, error } = await getStravaStatus(accessToken);
          if (data) {
            setStravaStatus(data.status);
          } else if (error) {
            setMessage(`Error: ${error.message}`);
          }
        }
      } catch (error) {
        setMessage(`Error: ${error instanceof Error ? error.message : 'Unknown error occurred'}`);
      } finally {
        setIsLoading(false);
      }
    };

    handleInitialLoad();
  }, [getAccessTokenSilently]);

  const handleDisconnect = async () => {
    setIsDisconnectPending(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await doDisconnect(accessToken);
      if (data) {
        setStravaStatus("Disconnected");
      } else if (error) {
        setMessage(`Error: ${error.message}`);
      }
    } catch (error) {
      setMessage(`Error: ${error instanceof Error ? error.message : 'Unknown error occurred'}`);
    } finally {
      setIsDisconnectPending(false);
    }
  };

  const handleConnect = () => {
    window.location.href = "http://www.strava.com/oauth/authorize?client_id=130034&response_type=code&redirect_uri=" + window.location.href + "&approval_prompt=force&scope=read,activity:read,activity:read_all";
  };

  return (
    <PageLayout>
      <div className="content-layout">
        <h1 className="text-slate-900 text-6xl tracking-tight font-extrabold sm:text-6xl dark:text-white mb-10">
          Connections
        </h1>
        <h2 className="text-slate-900 text-4xl tracking-tight font-extrabold sm:text-4xl dark:text-white mb-10">Strava</h2>
        <div className="content__body">
          {isLoading ? (
            <div className="flex justify-center items-center">
              <Loader2 className="h-8 w-8 animate-spin" />
            </div>
          ) : stravaStatus === "connected" ? (
            <>
              <p id="page-description">
                Your Strava status is: Connected
              </p>
              <button 
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded flex items-center justify-center"
                onClick={() => handleDisconnect()}
                disabled={isDisconnectPending}
              >
                {isDisconnectPending ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Disconnecting...
                  </>
                ) : (
                  "Disconnect Strava"
                )}
              </button>
            </>
          ) : (
            <>
              <p id="page-description">
                Your Strava status is: Disconnected
              </p>
              <button onClick={handleConnect} className="flex items-center justify-center">
                <img src={stravaConnectButton} alt="Connect to Strava" className="h-24 w-auto" />
              </button>
            </>
          )}
          {message && <p className="mt-4">{message}</p>}
        </div>
      </div>
    </PageLayout>
  );
};