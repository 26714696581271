import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { PageLayout } from "../components/page-layout";
import { Construction } from "lucide-react";

export const ProfilePage: React.FC = () => {
  const { user } = useAuth0();

  if (!user) {
    return null;
  }

  return (
    <PageLayout>
      <div className="content-layout">
        <div className="content__body">
          <div id="page-description" className="grid grid-cols-[auto_1fr] gap-4 items-center">
          <Construction className="text-yellow-500 w-8 h-8" />
          <p>
            <span>
              Under construction. This page will display your user profile information, including things like your best races at different distances,
              and your targeted upcoming races.
            </span>
          </p>
        </div>
          {/* <div className="profile-grid">
            <div className="profile__header">
              <img
                src={user.picture}
                alt="Profile"
                className="profile__avatar"
              />
              <div className="profile__headline">
                <h2 className="profile__title">{user.name}</h2>
                <span className="profile__description">{user.email}</span>
              </div>
            </div>
            <div className="profile__details">
              <CodeSnippet
                title="Decoded ID Token"
                code={JSON.stringify(user, null, 2)}
              />
            </div>
          </div> */}
        </div>
      </div>
    </PageLayout>
  );
};
