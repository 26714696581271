import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../../../assets/logo-horizontal-40height.png"

interface MobileNavBarBrandProps {
  handleClick: () => void;
}

export const MobileNavBarBrand: React.FC<MobileNavBarBrandProps> = ({
  handleClick,
}) => {
  return (
    <div onClick={handleClick} className="mobile-nav-bar__brand">
      <NavLink to="/">
        <img
          className="mobile-nav-bar__logo"
          src={logo}
          alt="Strongifier logo"
          height="24"
        />
      </NavLink>
    </div>
  );
};
