import React from "react";
import { PageFooterHyperlink } from "./page-footer-hyperlink";
import logo from "../assets/favicon-152.png";
import stravaLogo from "../assets/api_logo_cptblWith_strava_horiz_gray.png";

export const PageFooter = () => {
  return (
    <footer className="page-footer">
      <div className="page-footer-grid">
        <div className="page-footer-grid__brand flex flex-col items-center">
          <div className="page-footer-brand flex flex-col items-center">
            {/* Strongifier Logo - Doubled in size */}
            <img
              src={logo}
              alt="Strongifier"
              className="h-16 w-16 mb-2" // Doubled height and width
            />
            {/* Strongifier Link - Doubled font size */}
            <PageFooterHyperlink path="/">
              <span className="text-4xl font-bold">Strongifier</span>
            </PageFooterHyperlink>
          </div>

          {/* Compatible with Strava Logo - Halved in size */}
          <div className="mt-4">
            <img
              src={stravaLogo}
              alt="Compatible with Strava"
              className="h-12 w-auto" // Halved height
            />
          </div>
        </div>
      </div>
    </footer>
  );
};
