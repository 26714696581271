import React from "react";

interface StrongifierFeatureProps {
  title: string;
  description: string;
  resourceUrl: string;
}

export const StrongifierFeature: React.FC<StrongifierFeatureProps> = ({
  title,
  description,
  resourceUrl,
}) => (
  <a
    href={resourceUrl}
    className="auth0-feature"
    target="_blank"
    rel="noopener noreferrer"
  >
    <h3 className="auth0-feature__headline">
      {title}
    </h3>
    <p className="auth0-feature__description">{description}</p>
  </a>
);
