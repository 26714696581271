import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { CodeSnippet } from "../components/code-snippet";
import { PageLayout } from "../components/page-layout";
import { doChat, getChatHistory, clearChatHistory } from "../services/chat.service";
import { Loader, User, Bot, Trash2 } from 'lucide-react';
import Linkify from 'linkify-react';
import TextToHTML from "src/components/text-to-html";

// ActionButton Component
const ActionButton: React.FC<{ title: string; subtitle: string; handleAction: (title: string) => void }> = ({ title, subtitle, handleAction }) => (
  <button
    onClick={() => handleAction(title)}
    className="bg-gray-800 text-white p-4 rounded-lg flex flex-col items-start justify-center w-full h-32 transition-colors hover:bg-gray-700"
  >
    <span className="text-lg font-semibold">{title}</span>
    <span className="text-sm text-gray-400">{subtitle}</span>
  </button>
);

// Parent Component
export const ChatPage: React.FC = () => {
  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingChatResponse, setIsLoadingChatResponse] = useState(false);
  const [strongifierInput, setStrongifierInput] = useState('');
  const [chatHistory, setChatHistory] = useState<string[][] | null>(null);
  const { getAccessTokenSilently } = useAuth0();


  useEffect(() => {
    const handleInitialLoad = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const urlParams = new URLSearchParams(window.location.search);

        // Fetch chat history on initial page load.
        const { data, error } = await getChatHistory(accessToken);
        if (data) {
          //Convert the chat history from JSON string to an array of ChatEntry tuples
          setChatHistory(data);
          setIsLoading(false);
        } else if (error) {
          setMessage(`Error: ${error.message}`);
        }
        
      } catch (error) {
        setMessage(`Error: ${error instanceof Error ? error.message : 'Unknown error occurred'}`);
      } finally {
        setIsLoading(false);
      }
    };

    handleInitialLoad();
  }, [getAccessTokenSilently]);

  const handleAction = async (action: string) => {
    handleChatSubmit(action);
  };


  const handleStrongifierSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!strongifierInput.trim()) return;
    setStrongifierInput('');

    handleChatSubmit(strongifierInput);
  };

  const handleClearChat = async () => {
    //Set chat history to an empty array of arrays.
    setChatHistory([]);

    try {
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await clearChatHistory(accessToken);
      if (error) {
        setMessage(`Error: ${error.message}`);
      }
    } catch (error) {
      setMessage(`Error: ${error instanceof Error ? error.message : 'Unknown error occurred'}`);
    }
  }

  const handleChatSubmit = async (human_message: string) => {
    // Add the human message to the chat history
    setChatHistory(prevChatHistory => 
      prevChatHistory ? [...prevChatHistory, ['Human', human_message]] : [['Human', human_message]]
    );
    
    setIsLoadingChatResponse(true);
    
    try {
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await doChat(accessToken, human_message);
      
      if (data) {
        // Add the AI response to the chat history
        setChatHistory(prevChatHistory => 
          prevChatHistory ? [...prevChatHistory, ['AI', data]] : [['AI', data]]
        );
      } else if (error) {
        setMessage(`Error: ${error.message}`);
      }
    } catch (error) {
      setMessage(`Error: ${error instanceof Error ? error.message : 'Unknown error occurred'}`);
    } finally {
      setIsLoadingChatResponse(false);
    }
  }

  const data = [
    { name: '3 weeks ago', km: 20.67 },
    { name: '2 weeks ago', km: 26.79 },
    { name: 'Last week', km: 21.16 },
    { name: 'This week', km: 23.61 }
    ];

  return (
    <PageLayout>
      <div className="flex flex-col items-center justify-between bg-gray-900 p-4 text-white grow text-2xl">
        <div className="flex flex-col items-center grow">
          {/* Waiting / no-chat Logo */}
          { !isLoading && (!chatHistory || chatHistory.length == 0) ? (          
          <div className="mb-12 grow grid grid-cols-3 grid content-center">
            <svg className="w-16 h-16" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M50 10 L90 90 L10 90 Z" fill="white"/>
            </svg>
          </div>
          ) : (null)}

          {/* Chat Response Area */}
          {isLoading ? (
            <div className="mb-12 grow grid grid-cols-3 grid content-center">
              <Loader className="w-12 h-12 text-blue-500 animate-spin" />
            </div>
          ) : chatHistory && (
          <div className="mb-12 grow flex flex-col content-top p-6 space-y-4 overflow-y-auto">
            {chatHistory.map((entry, index) => {
              const [role, content] = entry;
              const isHuman = role === "Human";
              const isLastAI = index === chatHistory.length - 1 && role === "AI";

              return (
                <div
                  key={index}
                  className={`flex ${isHuman ? 'justify-end' : 'justify-start'}`}
                >
                  <div
                    className={`max-w-3/4 ${
                      isHuman
                        ? 'bg-blue-100 text-gray-800 rounded-l-lg rounded-br-lg ml-12'
                        : 'bg-gray-800 text-white rounded-r-lg rounded-bl-lg mr-12'
                    } p-4 shadow-md`}
                  >
                    <div className="flex items-start space-x-2">
                      {!isHuman && (
                        <Bot className="w-6 h-6 text-blue-500 mt-1 flex-shrink-0" />
                      )}
                      <TextToHTML content={content} />
                      {isHuman && (
                        <User className="w-6 h-6 text-blue-500 mt-1 flex-shrink-0" />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
            {isLoadingChatResponse && (
              <div className="flex justify-start">
                <div className="bg-gray-800 text-white rounded-r-lg rounded-bl-lg mr-12 p-4 shadow-md">
                  <div className="flex items-center space-x-2">
                    <Bot className="w-6 h-6 text-blue-500" />
                    <Loader className="w-6 h-6 text-blue-500 animate-spin" />
                  </div>
                </div>
              </div>
            )}
    </div>
          )}

              {/* Clear Chat Button */}
              { !isLoading && chatHistory && chatHistory.length > 0 ? (    

              <button
                onClick={handleClearChat}
                className="mb-6 w-4/5 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded flex items-center justify-center transition duration-300 ease-in-out"
              >
                <Trash2 className="mr-2" size={20} />
                Clear Chat
              </button>
              ) : (null)}

        {/* Action Buttons */}
        <div className="grid grid-cols-2 gap-4 mb-12 w-full">
          <ActionButton
            title="If I run a 29 minute 5k, how fast should I be able to run a half marathon in?"
            subtitle="Race Time Prediction"
            handleAction={handleAction}
          />
          <ActionButton
            title="Estimate My Threshold Speed and Heartrate over the past year"
            subtitle="Calculate Benchmark Run Stats"
            handleAction={handleAction}
          />
          <ActionButton
            title="Analyze my cardio zone distribution over the past month"
            subtitle="Understand Running History"
            handleAction={handleAction}
          />
          <ActionButton
            title="What should my next workout be?"
            subtitle="Optimize Training Plan"
            handleAction={handleAction}
          />
        </div>
          


        </div>

        {/* Message Strongifier */}
        <div className="w-full max-w-2xl mt-auto">
          <form onSubmit={handleStrongifierSubmit} className="flex items-center">
            <input
              type="text"
              value={strongifierInput}
              onChange={(e) => setStrongifierInput(e.target.value)}
              placeholder="Enter a message to Strongifier"
              className="flex-grow p-3 rounded-l-lg bg-gray-800 text-white border-2 border-r-0 border-gray-700 focus:outline-none focus:border-blue-500"
            />
            <button
              type="submit"
              className="bg-blue-500 text-white p-3 rounded-r-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            >
              Send
            </button>
          </form>
        </div>
      </div>
    </PageLayout>
  );
};