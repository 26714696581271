import React from "react";
import { StrongifierFeature } from "./strongifier-feature";

export const StrongifierFeatures: React.FC = () => {
  const featuresList = [
    {
      title: "Instantly Adapt",
      description:
        "Strongifier reacts to your every workout, providing real-time feedback and immediate updates to your training plan. Workout however you like, your training plan will adapt to you.",
      resourceUrl: "https://strongifier.com",
    },
    {
      title: "Just chat, don't hunt menus",
      description:
        "Access any coaching features through the chat interface. No need to hunt through menus or remember where to find a feature. Just ask and Strongifier will respond.",
      resourceUrl: "https://strongifier.com",
    },
    {
      title: "Reduce Injury Risk",
      description:
        "By carefully tracking your workout intensity and volume, Strongifier can help you avoid overtraining and reduce your risk of injury.",
      resourceUrl: "https://strongifier.com",
    },
    {
      title: "Detailed, Understandable Analysis",
      description:
        "Strongifiers can quickly analyze your entire workout history and explain which workouts are best for your next and why.",
      resourceUrl: "https://strongifier.com",
    },
  ];

  return (
    <div className="auth0-features">
      <h2 className="auth0-features__title">Explore Strongifier Features</h2>
      <div className="auth0-features__grid">
        {featuresList.map((feature) => (
          <StrongifierFeature
            key={feature.resourceUrl}
            title={feature.title}
            description={feature.description}
            resourceUrl={feature.resourceUrl}
          />
        ))}
      </div>
    </div>
  );
};
