import { AxiosRequestConfig } from "axios";
import { callExternalApi } from "./external-api.service";
import { StravaDisconnectResponse } from "../models/strava-disconnect-response";
import { StravaConnectionStatus } from "../models/strava-connection-status";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const doDisconnect = async (
  accessToken: string
): Promise<StravaDisconnectResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/strava/disconnect`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = (await callExternalApi({ config })) as StravaDisconnectResponse;




  return {
    data,
    error,
  };
};

export const getStravaStatus = async (
  accessToken: string
): Promise<StravaConnectionStatus> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/strava/status`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = (await callExternalApi({ config })) as StravaConnectionStatus;




  return {
    data,
    error,
  };
};

export const doConnect = async (
  accessToken: string,
  inputData: string
): Promise<StravaConnectionStatus> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/strava/connect`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: { "authorization_token": inputData }
  };

  const { data, error } = (await callExternalApi({ config })) as StravaDisconnectResponse;




  return {
    data,
    error,
  };
};