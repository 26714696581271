import React from 'react';
import Linkify from 'linkify-react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
} from 'recharts';

interface ChartData {
  chart_title: string;
  lines: string[] | string;
  data: { [key: string]: any }[];
}

interface ChartComponentProps {
  data: ChartData;
}

const COLORS = [
  '#43aa8b', // Soft Teal
  '#ff5722', // Bright Warm Orange
  '#f2c12e', // Golden Yellow
  '#495867', // Dusty Blue Gray
  '#b0e0e6', // Pale Powder Blue
  '#bf9b30', // Muted Mustard
  '#ecb390', // Peachy Beige
  '#7d8c7f', // Muted Sage
  '#1f4068', // Dark Slate Blue
  '#6b4226', // Warm Chestnut Brown
];

const ChartComponent: React.FC<ChartComponentProps> = ({ data }) => {
  const { chart_title, lines, data: chartData } = data;

  // Ensure lines is an array
  const lineKeys = Array.isArray(lines) ? lines : [lines];

  return (
    <>
      <h3 className="text-white">{chart_title}</h3>
      <ResponsiveContainer width="100%" height={200}>
        <LineChart data={chartData}>
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          {lineKeys.map((lineKey: string, index: number) => (
            <Line
              key={index}
              type="monotone"
              dataKey={lineKey}
              stroke={COLORS[index % COLORS.length]}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

interface PieChartData {
  chart_title: string;
  data: { name: string; value: number }[];
}

interface PieChartComponentProps {
  data: PieChartData;
}

const PieChartComponent: React.FC<PieChartComponentProps> = ({ data }) => {
  const { chart_title, data: chartData } = data;

  return (
    <>
      <h3 className="text-white">{chart_title}</h3>
      <ResponsiveContainer width="100%" height={200}>
        <PieChart>
          <Pie
            data={chartData}
            dataKey="value"
            nameKey="name"
            outerRadius={80}
            label
          >
            {chartData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </>
  );
};

interface BarChartDataPoint {
  name: string;
  [key: string]: number | string; // Allow both number and string types
}

interface BarChartData {
  chart_title: string;
  x_axis_label: string;
  y_axis_label: string;
  data: BarChartDataPoint[];
}

interface BarChartComponentProps {
  data: BarChartData;
}

const BarChartComponent: React.FC<BarChartComponentProps> = ({ data }) => {
  const { chart_title, x_axis_label, y_axis_label, data: chartData } = data;

  // Extract bar keys (excluding 'name' property and ensuring they are numbers)
  const barKeys = Object.keys(chartData[0]).filter(
    key => key !== 'name' && typeof chartData[0][key] === 'number'
  );

  return (
    <>
      <h3 className="text-white">{chart_title}</h3>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={chartData}>
          <XAxis
            dataKey="name"
            label={{ value: x_axis_label, position: 'insideBottom', dy: 10 }}
          />
          <YAxis
            label={{ value: y_axis_label, angle: -90, position: 'insideLeft' }}
          />
          <Tooltip />
          <Legend />
          {barKeys.map((key, index) => (
            <Bar
              key={key}
              dataKey={key}
              fill={COLORS[index % COLORS.length]}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

function processContent(content: string): React.ReactNode[] {
  const elements: React.ReactNode[] = [];

  // Updated regex to match line, pie, and bar chart code blocks
  const rechartRegex =
    /```(rechart-(line|pie|bar)-chart)\s*\n([\s\S]*?)\n```/g;

  let lastIndex = 0;
  let match: RegExpExecArray | null;

  while ((match = rechartRegex.exec(content)) !== null) {
    const matchIndex = match.index;

    // Get the text before the code block
    const beforeCode = content.slice(lastIndex, matchIndex);
    if (beforeCode) {
      elements.push(
        ...beforeCode.split('\n').map((line: string, index: number) => (
          <React.Fragment key={`text-${matchIndex}-${index}`}>
            {line}
            <br />
          </React.Fragment>
        ))
      );
    }

    // Get the chart type and code content
    const chartType = match[2]; // 'line', 'pie', or 'bar'
    const codeContent = match[3];

    // Parse the JSON content
    let chartData;

    try {
      chartData = JSON.parse(codeContent);

      // Now, create the appropriate chart component
      if (chartType === 'line') {
        elements.push(
          <React.Fragment key={`chart-${matchIndex}`}>
            <ChartComponent data={chartData} />
          </React.Fragment>
        );
      } else if (chartType === 'pie') {
        elements.push(
          <React.Fragment key={`chart-${matchIndex}`}>
            <PieChartComponent data={chartData} />
          </React.Fragment>
        );
      } else if (chartType === 'bar') {
        elements.push(
          <React.Fragment key={`chart-${matchIndex}`}>
            <BarChartComponent data={chartData} />
          </React.Fragment>
        );
      }
    } catch (error) {
      console.error('Failed to parse chart data:', error);
      elements.push(
        <span className="text-red-500" key={`error-${matchIndex}`}>
          Invalid chart data
        </span>
      );
    }

    // Update lastIndex
    lastIndex = rechartRegex.lastIndex;
  }

  // Process any remaining text after the last code block
  const afterCode = content.slice(lastIndex);
  if (afterCode) {
    const remainingIndex = lastIndex;
    elements.push(
      ...afterCode.split('\n').map((line: string, index: number) => (
        <React.Fragment key={`text-${remainingIndex}-${index}`}>
          {line}
          <br />
        </React.Fragment>
      ))
    );
  }

  return elements;
}

interface TextToHTMLProps {
  content: string;
}

const TextToHTML: React.FC<TextToHTMLProps> = ({ content }) => {
  return (
    <div className="prose max-w-none overflow-hidden">
      <Linkify
        options={{
          target: '_blank',
          rel: 'noopener noreferrer',
        }}
      >
        {typeof content === 'string' ? (
          processContent(content)
        ) : (
          (() => {
            console.error(
              `Invalid content type: ${typeof content}. Content:`,
              content
            );
            return (
              <span className="text-red-500">
                Invalid content (Type: {typeof content})
              </span>
            );
          })()
        )}
      </Linkify>
    </div>
  );
};

export default TextToHTML;
