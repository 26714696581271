import { AxiosRequestConfig } from "axios";
import { callExternalApi } from "./external-api.service";
import { ChatApiResponse } from "../models/chat-api-response";
import { ChatHistoryApiResponse } from "../models/chat-history-response";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const doChat = async (
  accessToken: string,
  inputData: string
): Promise<ChatApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/chat`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: { "question": inputData }
  };

  const { data, error } = (await callExternalApi({ config })) as ChatApiResponse;


  return {
    data,
    error,
  };
};

//Clear Chat History POST service function.
export const clearChatHistory = async (
  accessToken: string
): Promise<ChatHistoryApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/chat/history/clear`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = (await callExternalApi({ config })) as ChatHistoryApiResponse;

  return {
    data,
    error,
  };
};

export const getChatHistory = async (
  accessToken: string
): Promise<ChatHistoryApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/chat/history`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = (await callExternalApi({ config })) as ChatHistoryApiResponse;




  return {
    data,
    error,
  };
};